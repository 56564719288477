<template>
  <div class="Chain">
    <!-- 面包屑导航区域 -->
    <Breadcrumb name1="商品管理" name2="公链列表" />
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-button type="primary" @click="showAddChainDialog"
          >添加Chain</el-button
        >
      </el-row>
      <el-row>
        <!-- 表格 -->
        <tree-table
          :data="chainList"
          :expand-type="false"
          :selection-type="false"
          show-index
          class="treetable"
          index-text="#"
          stripe
          border
          :show-row-hover="false"
          :columns="columns"
        >
        <template v-slot:category="scope">
          <el-tag size="mini" v-if="scope.row.category==1">公链</el-tag>
          <el-tag size="mini" type="warning" v-else>联盟链</el-tag>
        </template>
          <!-- 是否有效 -->
          <!-- <template slot='isok' v-slot="scope"> -->
          <template v-slot:isok="scope">
            <i
              class="el-icon-success"
              v-if="scope.row.is_del == 0"
              style="color: lightgreen"
            ></i>
            <i class="el-icon-error" style="color: red" v-else></i>
          </template>
          <!-- 排序 -->
          <!-- <template v-slot:order="scope">
            <el-tag size="mini" v-if="scope.row.cat_level === 0">一级</el-tag>
            <el-tag size="mini" type="success" v-else-if="scope.row.cat_level === 1">二级</el-tag>
            <el-tag size="mini" type="warning" v-else>三级</el-tag>
          </template> -->
          <template v-slot:opt="scope">
            <el-button
              type="primary"
              @click="showeditChainDialog(scope.row)"
              icon="el-icon-edit"
              size="mini"
            ></el-button>
            <el-button
              type="danger"
              @click="removeChain(scope.row.id)"
              icon="el-icon-delete"
              size="mini"
            ></el-button>
          </template>
        </tree-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryInfo.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <!-- 添加公链的对话框   -->
    <el-dialog
      :close-on-click-modal=false
      title="添加/修改Chain"
      :visible.sync="addChainDialogVisible"
      width="50%"
      @close="addChainDialogClosed"
    >
      <el-form
        :model="addChainForm"
        :rules="addChainFormRules"
        ref="addChainFormRef"
        label-width="100px"
      >
        <el-form-item label="链类型" prop="category">
          <el-select v-model="addChainForm.category" placeholder="请选择">
            <el-option label="公链" value="1"></el-option>
            <el-option label="联盟链" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="chain_id:" prop="chain_id">
          <el-input v-model="addChainForm.chain_id" type="number"></el-input>
        </el-form-item>
        <el-form-item label="chain名称:" prop="chain_name">
          <el-input v-model="addChainForm.chain_name"></el-input>
        </el-form-item>
        <el-form-item label="浏览器地址:" prop="explorer_url">
          <el-input v-model="addChainForm.explorer_url"></el-input>
        </el-form-item>
        <el-form-item label="rpc地址:" prop="rpc_url">
          <el-input v-model="addChainForm.rpc_url"></el-input>
        </el-form-item>
        <el-form-item label="合约地址:" prop="contract_address">
          <el-input v-model="addChainForm.contract_address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addChainDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addChain">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑公链信息 -->
    <!-- <el-dialog title="修改公链" :visible.sync="editChainDialogVisbel" width="50%">
      <el-form :model="editChain" :rules="editChainRules" ref="editChainRef" label-width="100px">
        <el-form-item label="公链名称" prop="cat_name">
          <el-input v-model="editChain.cat_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editChainDialogVisbel = false">取 消</el-button>
        <el-button type="primary" @click="editChainInfo">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Chain',
  components: {
    Breadcrumb, // 面包屑
  },
  data() {
    return {
      // 商品公链的数据列表,默认为空
      chainList: [],
      // 请求参数
      queryInfo: {
        type: 3,
        page: 1,
        page_size: 10,
      },
      // 总数据条数
      total: 0,
      // 为table指定列的定义
      columns: [
       
        {
          label: 'chain_id',
          prop: 'chain_id',
        },
        {
          label: 'chain名称',
          prop: 'chain_name',
        },
        {
          label: '浏览器url',
          prop: 'explorer_url',
        },
        {
          label: 'rpc',
          prop: 'rpc_url',
        },
        {
          label: '合约地址',
          prop: 'contract_address',
        },
        {
          label: '链类型',
          // prop:'category',
          type: 'template',
          template: 'category',
        },
        {
          label: '是否有效',
          // 表示,将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用模板名称
          template: 'isok',
        },

        {
          label: '操作',
          // 表示,将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用模板名称
          template: 'opt',
        },
      ],
      // 添加对话框的公链和隐藏
      addChainDialogVisible: false,
      // 添加公链的表单数据对象
      addChainForm: {
        chain_id: '',
        // 将要添加公链的名称
        chain_name: '',
        // 节约地址
        contract_address: '',
        //浏览器地址
        explorer_url: '',
        //rpc_url
        rpc_url: '',
        category:'',
      },
      // 添加公链表单的验证规则对象
      addChainFormRules: {
        category: [
          { required: true, message: '请选择链类型', trigger: 'blur' },
        ],
        chain_id: [
          { required: true, message: '请输入chain_id', trigger: 'blur' },
        ],
        chain_name: [
          { required: true, message: '公链名称', trigger: 'blur' },
          {
            min: 2,
            max: 100,
            message: '公链名称在2~100字符以内',
            trigger: 'blur',
          },
        ],
        explorer_url: [
          { required: true, message: '浏览器地址', trigger: 'blur' },
          {
            min: 10,
            max: 255,
            message: '浏览器地址在10~255字符以内',
            trigger: 'blur',
          },
        ],
        rpc_url: [
          { required: true, message: 'rpc地址', trigger: 'blur' },
          {
            min: 2,
            max: 100,
            message: 'rpc地址在10~100字符以内',
            trigger: 'blur',
          },
        ],
        contract_address: [
          { required: true, message: '合约地址', trigger: 'blur' },
          {
            min: 10,
            max: 100,
            message: '合约地址在10~255字符以内',
            trigger: 'blur',
          },
        ],
      },
      // 父级公链的列表
      // parentChainList: [],
      // // 指定级联选择器的配置对象
      // cascaderProps: {
      //   value: 'cat_id',
      //   label: 'cat_name',
      //   children: 'children'
      // },
      // // 选中的父级公链的id数组
      // selectdKeys: [],
      // // 编辑对话框的显示和隐藏
      // editChainDialogVisbel: false,
      // editChain: {},
      // editChainRules: {
      //   cat_name: [{ required: true, message: '请输入要修改的信息', trigger: 'blur' }]
      // },
      editChainId: '',
    }
  },
  created() {
    this.getChainList()
  },
  methods: {
    // 获取商品公链数据
    async getChainList() {
      this.$http({
        method: 'get',
        url: '/api/v1/admin/chain-infos',
        params: this.queryInfo,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          this.chainList = res.data.list
          // 获取商品的总数量
          this.total = res.data.pager.total_rows
        })
        .catch((res) => {
          return this.$message.error('获取商品公链失败!')
        })
    },
    // 监听pagesize改变
    handleSizeChange(newValue) {
      this.queryInfo.page_size = newValue
      this.getChainList()
    },
    // 监听页码的改变
    handleCurrentChange(newpage) {
      this.queryInfo.page = newpage
      this.getChainList()
    },
    // 添加公链对话框
    showAddChainDialog() {
      this.addChainDialogVisible = true
    },
    // 点击按钮添加新的公链
    addChain() {
      this.$refs.addChainFormRef.validate(async (valid) => {
        if (!valid) return
        // return
        this.addChainForm.chain_id = Number(this.addChainForm.chain_id)
        this.addChainForm.category = Number(this.addChainForm.category)
        if (this.editChainId) {
          this.$http({
            method: 'put',
            url: '/api/v1/admin/chain-infos/'+this.editChainId,
            data: this.addChainForm,
            timeout: 10000,
            headers: {
              token: window.sessionStorage.getItem('token'),
              'Content-Type': 'application/json; charset=UTF-8',
            },
          })
            .then((res) => {
              this.$message.success('修改公链成功!')
              this.getChainList()
              this.addChainDialogVisible = false
            })
            .catch((res) => {
              return this.$message.error('修改公链失败!')
            })
        } else {
          this.$http({
            method: 'post',
            url: '/api/v1/admin/chain-infos',
            data: this.addChainForm,
            timeout: 10000,
            headers: {
              token: window.sessionStorage.getItem('token'),
              'Content-Type': 'application/json; charset=UTF-8',
            },
          })
            .then((res) => {
              this.$message.success('添加公链成功!')
              this.getChainList()
              this.addChainDialogVisible = false
            })
            .catch((res) => {
              return this.$message.error('添加公链失败!')
            })
        }
      })
    },
    // 关闭对话框事件,重置表单数据
    addChainDialogClosed() {
      this.editChainId = ''
      this.addChainForm.chain_id = 0
      this.addChainForm.chain_name = ''
      this.addChainForm.contract_address = ''
      this.addChainForm.rpc_url = ''
      this.addChainForm.explorer_url = ''
      this.addChainForm.category = ''
    },
    async showeditChainDialog(ChainInfo) {
      console.log(ChainInfo)
      this.addChainDialogVisible = true
      this.editChainId = ChainInfo.id
      this.addChainForm.chain_id = ChainInfo.chain_id
      this.addChainForm.chain_name = ChainInfo.chain_name
      this.addChainForm.contract_address = ChainInfo.contract_address
      this.addChainForm.rpc_url = ChainInfo.rpc_url
      this.addChainForm.explorer_url = ChainInfo.explorer_url
      this.addChainForm.category = ChainInfo.category.toString()
    },

    // 删除公链
    async removeChain(id) {
      const confirRustle = await this.$confirm(
        '此操作将删除该公链, 是否继续?',
        '删除公链',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)

      if (confirRustle !== 'confirm') {
        return this.$message.info('已取消删除操作!')
      }

      this.$http({
        method: 'delete',
        url: '/api/v1/admin/chain-infos/' + id,
        data: this.addChainForm,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          this.$message.success('该公链已经成功删除!')
          this.getChainList()
        })
        .catch((res) => {
          return this.$message.error('公链删除失败!')
        })
    },
  },
}
</script>

<style lang="less" scoped>
.treetable {
  margin-top: 15px;
}
.el-cascader {
  width: 100%;
}
</style>
