<template>
  <div class="cate">
    <!-- 面包屑导航区域 -->
    <Breadcrumb name1="商品管理" name2="商品分类" />
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-button type="primary" @click="showAddCateDialog"
          >添加分类</el-button
        >
      </el-row>
      <el-row>
        <!-- 表格 -->
        <tree-table
          :data="cateList"
          :expand-type="false"
          :selection-type="false"
          show-index
          class="treetable"
          index-text="#"
          stripe
          border
          :show-row-hover="false"
          :columns="columns"
        >
          <!-- 是否有效 -->
          <!-- <template slot='isok' v-slot="scope"> -->
          <template v-slot:isok="scope">
            <i
              class="el-icon-success"
              v-if="scope.row.is_del == 0"
              style="color: lightgreen"
            ></i>
            <i class="el-icon-error" style="color: red" v-else></i>
          </template>
          <!-- 排序 -->
          <!-- <template v-slot:order="scope">
            <el-tag size="mini" v-if="scope.row.cat_level === 0">一级</el-tag>
            <el-tag size="mini" type="success" v-else-if="scope.row.cat_level === 1">二级</el-tag>
            <el-tag size="mini" type="warning" v-else>三级</el-tag>
          </template> -->
          <template v-slot:opt="scope">
            <el-button
              type="primary"
              @click="showeditCateDialog(scope.row)"
              icon="el-icon-edit"
              size="mini"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="removeCate(scope.row.id)"
              icon="el-icon-delete"
              size="mini"
              >删除</el-button
            >
          </template>
        </tree-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryInfo.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <!-- 添加分类的对话框   -->
    <el-dialog
      title="添加/修改分类"
      :visible.sync="addCateDialogVisible"
      width="50%"
      @close="addCateDialogClosed"
    >
      <el-form
        :model="addCateForm"
        :rules="addCateFormRules"
        ref="addCateFormRef"
        label-width="100px"
      >
        <el-form-item label="分类名称:" prop="name">
          <el-input v-model="addCateForm.name"></el-input>
        </el-form-item>
        <el-form-item label="父级描述:" prop="desc">
          <el-input v-model="addCateForm.desc"></el-input>
          <!-- options 用来指定数据源 -->
          <!-- props 用来指定配置对象 -->
          <!-- <el-cascader
            v-model="selectdKeys"
            :options="parentCateList"
            :props="{
              expandTrigger: 'hover',
              ...cascaderProps,
              checkStrictly: 'true'
            }"
            @change="parentCateChanged"
            clearable
          ></el-cascader> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑分类信息 -->
    <!-- <el-dialog title="修改分类" :visible.sync="editCateDialogVisbel" width="50%">
      <el-form :model="editCate" :rules="editCateRules" ref="editCateRef" label-width="100px">
        <el-form-item label="分类名称" prop="cat_name">
          <el-input v-model="editCate.cat_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editCateDialogVisbel = false">取 消</el-button>
        <el-button type="primary" @click="editCateInfo">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Cate',
  components: {
    Breadcrumb, // 面包屑
  },
  data() {
    return {
      // 商品分类的数据列表,默认为空
      cateList: [],
      // 请求参数
      queryInfo: {
        // created_by: "",
        type: 3,
        page: 1,
        page_size: 10,
      },
      // 总数据条数
      total: 0,
      // 为table指定列的定义
      columns: [
        {
          label: '分类名称',
          prop: 'name',
        },
        {
          label: '分类描述',
          // 表示,将当前列定义为模板列
          prop: 'desc',
        },
        {
          label: '是否有效',
          // 表示,将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用模板名称
          template: 'isok',
        },

        {
          label: '操作',
          // 表示,将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用模板名称
          template: 'opt',
        },
      ],
      // 添加对话框的分类和隐藏
      addCateDialogVisible: false,
      // 添加分类的表单数据对象
      addCateForm: {
        // 将要添加分类的名称
        name: '',
        // 将要添加分类的描述
        desc: '',
      },
      // 添加分类表单的验证规则对象
      addCateFormRules: {
        name: [
          {
            required: true,
            message: '请输入分类名称， 长度在2到255个字符',
            trigger: 'blur',
            min: 2,
            max: 255,
          },
        ],
        desc: [
          {
            required: true,
            message: '请输入分类描述， 长度在2到255个字符',
            trigger: 'blur',
            min: 2,
            max: 255,
          },
        ],
      },
      // 父级分类的列表
      parentCateList: [],
      // 指定级联选择器的配置对象
      cascaderProps: {
        value: 'cat_id',
        label: 'cat_name',
        children: 'children',
      },
      // 选中的父级分类的id数组
      selectdKeys: [],
      // 编辑对话框的显示和隐藏
      editCateDialogVisbel: false,
      editCate: {},
      editCateRules: {
        cat_name: [
          { required: true, message: '请输入要修改的信息', trigger: 'blur' },
        ],
      },
      editCateId: '',
    }
  },
  created() {
    this.getCateList()
  },
  methods: {
    // 获取商品分类数据
    async getCateList() {
      this.$http({
        method: 'get',
        url: '/api/v1/admin/categories',
        params: this.queryInfo,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          // const { data: response } = res
          this.cateList = res.data.list
          // 获取商品的总数量
          this.total = res.data.pager.total_rows
        })
        .catch((res) => {
          return this.$message.error('获取商品分类失败!')
        })
    },
    // 监听pagesize改变
    handleSizeChange(newValue) {
      this.queryInfo.page_size = newValue
      this.getCateList()
    },
    // 监听页码的改变
    handleCurrentChange(newpage) {
      this.queryInfo.page = newpage
      this.getCateList()
    },
    // 添加分类对话框
    showAddCateDialog() {
      // this.getParentCateList()
      this.addCateDialogVisible = true
    },
    // 点击按钮添加新的分类
    addCate() {
      this.$refs.addCateFormRef.validate(async (valid) => {
        if (!valid) return
        if (this.editCateId) {
            this.$http({
            method: 'put',
            url: '/api/v1/admin/categories/' + this.editCateId,
            data: this.addCateForm,
            timeout: 10000,
            headers: {
              token: window.sessionStorage.getItem('token'),
              // 'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json; charset=UTF-8',
            },
            // loading: loading
          })
            .then((res) => {
              this.$message.success('修改分类成功!')
              this.getCateList()
              this.addCateDialogVisible = false
            })
            .catch((res) => {
              this.$message.error('修改分类失败!')
            })
        } else {
          this.$http({
            method: 'post',
            url: '/api/v1/admin/categories',
            data: this.addCateForm,
            timeout: 10000,
            headers: {
              token: window.sessionStorage.getItem('token'),
              // 'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json; charset=UTF-8',
            },
            // loading: loading
          })
            .then((res) => {
              this.$message.success('添加分类成功!')
              this.getCateList()
              this.addCateDialogVisible = false
            })
            .catch((res) => {
              this.$message.error('添加分类失败!')
            })
        }
        
      })
    },
    // 关闭对话框事件,重置表单数据
    addCateDialogClosed() {
      this.$refs.addCateFormRef.resetFields()
      this.selectdKeys = []
      this.addCateForm.name = ''
      this.addCateForm.desc = ''
    },
    async showeditCateDialog(cateInfo) {
      this.addCateDialogVisible = true
      this.editCateId = cateInfo.id
      this.addCateForm.name = cateInfo.name
      this.addCateForm.desc = cateInfo.desc
    },

    // 删除分类
    async removeCate(id) {
      const confirRustle = await this.$confirm(
        '此操作将删除该分类, 是否继续?',
        '删除分类',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)

      if (confirRustle !== 'confirm') {
        return this.$message.info('已取消删除操作!')
      }

      this.$http({
        method: 'delete',
        url: '/api/v1/admin/categories/'+id,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          this.$message.success('该分类已经成功删除!')
          this.getCateList()
        })
        .catch((res) => {
          return this.$message.error('分类删除失败!')
        })
    },
  },
}
</script>

<style lang="less" scoped>
.treetable {
  margin-top: 15px;
}
.el-cascader {
  width: 100%;
}
</style>
